import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import { init } from '@amplitude/analytics-browser';

init(process.env.VUE_APP_AMPLITUDE_API_KEY, {
    defaultTracking: false,
  });

createApp(App).use(router).mount('#app');
