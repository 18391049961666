<template>
  <div class="test-row__input">
    <input
      @change="onChange"
      :style="style"
      :min="min"
      :max="max"
      :value="value"
      :placeholder="placeholder"
      :type="type ? type : 'text'"
      :class="error ? 'test-input test-input__error' : 'test-input'"
      required
    />
    <span v-if="addon != ''" class="test-input__text">{{ addon }}</span>
  </div>
</template>

<script>
export default {
  name: "MyInput",
  props: {
    min: Number,
    max: Number,
    type: String,
    style: String,
    value: Number,
    addon: String,
    error: Boolean,
    placeholder: String,
  },
  methods: {
    onChange(event) {
      if (!event) return;
      this.$emit("onChange", event.target.value);
    },
  },
};
</script>

<style scoped>
.test-row__input {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}

.test-input__error {
  border: 1px solid var(--primary) !important;
}

.test-input {
  padding: 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--font);
  background-color: var(--secondary);
  border-radius: 1rem;
  max-width: 6rem;
}

.test-input__text {
  font-size: 1rem;
  font-weight: 500;
}
</style>
