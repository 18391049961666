<template>
  <div :class="cls ? `${cls} test-part` : 'test-part'">
    <div class="test-part__header">
      <h2 class="test-title">{{ title }}</h2>
      <button class="test-header__button" v-if="showButton" @click="onEdit">
        {{ locale.edit_q6[lang] }}
      </button>
    </div>
    <div class="test-part__header" v-if="description != ''">
      <p class="test-description">{{ description }}</p>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
    name: 'TestPart',
    props: {
        cls: String,
        lang: String,
        locale: Object,
        title: String,
        description: String,
        showButton: Boolean
    },
    methods: {
        onEdit() {
            this.$emit('onEdit');
        }
    },
}
</script>

<style scoped>
.test-part {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  width: 100%;
}

.test-part__header {
  width: 100%;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  gap: 1rem;
}

.test-header__button {
  background-color: transparent;

  color: var(--font);
  border: 2px solid var(--active);
  padding: 1rem 1.75rem;
  border-radius: 12px;

  font-size: 1rem;
  font-weight: 500;
}

.test-title {
  width: 100%;
  text-align: left;
}

.test-description {
  width: 100%;
  text-align: left;
  font-size: 1rem;
}
</style>
