<template>
  <MyHeader userNick="Anonimus" />
  <MyNavigation :navs="navs" />
  <MyTest
    :preview="getImageUrl('./q_4.png')"
    :lang="lang"
    nextPage="Talk"
    :title="locale.title_q4[this.lang]"
    :description="locale.description_q4[this.lang]"
    :isButtonDisabled="subGoal === null && (weightChange === null ||  weightGoal === null)"
  >
    <TestForm>
      <TestRow :title="locale.goal_q4[this.lang]">
        <MyOption :items="items" @onSelect="onSelectGoal" />
      </TestRow>
      <TestRow :title="locale.want_q4[this.lang]" v-if="sel == 0">
        <MyOption :items="items_goals" @onSelect="onSelectSubGoal"/>
      </TestRow>
      <TestRow :title="locale.w_goal_q4[this.lang]" v-if="sel == 1">
        <MyInput v-if="metric != 0" type="number" addon="kg" @onChange="onWeightGoal"/>
        <MyInput v-if="metric == 0" type="number" addon="lbs" @onChange="onWeightGoal"/>
      </TestRow>
      <TestRow
        :title="locale.w_change_q4[this.lang]"
        :notify="notify"
        v-if="sel == 1"
      >
        <MyInput
          v-if="metric != 0"
          type="number"
          :addon="locale.week_q4[this.lang]"
          @onChange="onKgExcept"
        />
        <MyInput
          v-if="metric == 0"
          type="number"
          :addon="locale.week_lbs_q4[this.lang]"
          @onChange="onKgExcept"
        />
      </TestRow>
    </TestForm>
  </MyTest>
</template>

<script>
import data from "@/locale";
import MyTest from "@/components/Test.vue";
import MyHeader from "@/components/Header.vue";
import MyNavigation from "@/components/Navigation.vue";

import TestRow from "@/components/TestRow.vue";
import TestForm from "@/components/TestForm.vue";
import MyOption from "@/components/Option.vue";
import MyInput from "@/components/Input.vue";

export default {
  name: "UnitsPage",
  components: {
    MyTest,
    MyHeader,
    MyNavigation,
    TestRow,
    TestForm,
    MyOption,
    MyInput,
  },
  methods: {
    getImageUrl(path) {
      let images = require.context("@/assets/", false, /\.png$|\.jpg$/);

      return images(path);
    },
    onSelectGoal(item) {
      if (item < 0) return;

      this.sel = item;
      this.subGoal = null
      this.weightGoal = null
      this.weightChange = null
    },
    onSelectSubGoal(item) {
        if (item < 0) return;

        this.subGoal = item;
    },
    onKgExcept(value) {
      let f = parseFloat(value);
    
      this.weightChange = f

      if (this.metric == 0)
        this.notify = f > 2 ? this.locale.lbs_warn_q4[this.lang] : "";
      if (this.metric != 0)
        this.notify = f > 0.9 ? this.locale.kg_warn_q4[this.lang] : "";
    },
    onWeightGoal(value) {
        let f = parseFloat(value);
        this.weightGoal = f
    },

    loadData() {
      this.metric = localStorage.getItem("metric")
        ? localStorage.getItem("metric")
        : 0;
    },
  },
  created() {
    this.lang =
      !this.$route.params.locale ||
      !this.supportedLangs.includes(this.$route.params.locale)
        ? "en"
        : this.$route.params.locale;

    (this.items = [
      this.locale.m_goal_q4[this.lang],
      this.locale.e_goal_q4[this.lang],
    ]),
      (this.items_goals = [
        this.locale.lose_q4[this.lang],
        this.locale.maintain_q4[this.lang],
        this.locale.muscle_q4[this.lang],
      ]);

    this.loadData();
  },
  data() {
    return {
      locale: data.data,
      lang: "en",
      supportedLangs: ["en", "cz"],
      sel: null,
      notify: "",
      kgExcept: 0,
      metric: 0,
      navs: [
        "nav-item nav-item-past",
        "nav-item nav-item-past",
        "nav-item nav-item-past",
        "nav-item nav-parrot",
        "nav-item",
        "nav-item",
      ],
      items: [],
      items_goals: [],
      subGoal: null,
      weightGoal: null,
      weightChange: null
    };
  },
};
</script>
