<template>
  <MyHeader userNick="Anonimus" />
  <MyNavigation :navs="navs" />
  <MyTest
    :preview="getImageUrl('./q_1.png')"
    :lang="lang"
    nextPage="Exclusion"
    :title="locale.title_q1[this.lang]"
    :description="locale.description_q1[this.lang]"
    :tiple="locale.tiple_q1[this.lang]"
    :isButtonDisabled="selectedIndex === null"
  >
    <ItemRatio
      v-for="(item, index) in items"
      @click="onClickItem(index)"
      :classes="selectedIndex == index ? 'item-v1 item-v1-click' : 'item-v1'"
      :key="index"
      :img="item.src"
      :title="item.title"
      :description="item.description"
    />
  </MyTest>
</template>

<script>
import data from "@/locale";
import MyTest from "@/components/Test.vue";
import MyHeader from "@/components/Header.vue";
import ItemRatio from "@/components/ItemRatio.vue";
import MyNavigation from "@/components/Navigation.vue";

export default {
  name: "IndexPage",
  components: {
    MyTest,
    MyHeader,
    ItemRatio,
    MyNavigation,
  },
  methods: {
    getImageUrl(path) {
      let images = require.context("@/assets/", false, /\.png$|\.jpg$/);

      return images(path);
    },
    onClickItem(index) {
      if (index < 0) return;

      this.selectedIndex = index;
    },
  },
  created() {
    this.lang = this.$router.currentRoute.value.path.includes("cz")
      ? "cz"
      : "en";

    this.items = [
      {
        src: this.getImageUrl("./q1_anything.png"),
        title: this.locale.a1_q1[this.lang],
        description: this.locale.l1_q1[this.lang],
      },
      {
        src: this.getImageUrl("./q1_keto.png"),
        title: this.locale.a2_q1[this.lang],
        description: this.locale.l2_q1[this.lang],
      },
      {
        src: this.getImageUrl("./q1_mediterranean.png"),
        title: this.locale.a3_q1[this.lang],
        description: this.locale.l3_q1[this.lang],
      },
      {
        src: this.getImageUrl("./q1_paleo.png"),
        title: this.locale.a4_q1[this.lang],
        description: this.locale.l4_q1[this.lang],
      },
      {
        src: this.getImageUrl("./q1_vegan.png"),
        title: this.locale.a5_q1[this.lang],
        description: this.locale.l5_q1[this.lang],
      },
      {
        src: this.getImageUrl("./q1_vegetarian.png"),
        title: this.locale.a6_q1[this.lang],
        description: this.locale.l6_q1[this.lang],
      },
    ];
  },
  data() {
    return {
      locale: data.data,
      lang: "en",
      supportedLangs: ["en", "cz"],
      navs: [
        "nav-item nav-parrot",
        "nav-item",
        "nav-item",
        "nav-item",
        "nav-item",
        "nav-item",
      ],
      items: [],
      selectedIndex: null,
    };
  },
};
</script>
