<template>
  <div class="popup-container">
    <div class="popup" @click.stop>
      <h1 class="popup-title">{{ locale.enter_mail[lang] }}</h1>
      <form class="mail">
        <input
          type="mail"
          class="mail-input"
          placeholder="Enter e-mail"
          required
        />
        <button type="button" class="mail-button" @click="onMailClick($event)">
          {{ locale.confirm[lang] }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import data from "@/locale";

export default {
  name: "MailPopup",
  props: {
    lang: String,
  },
  methods: {
    onMailClick(event) {
      event.stopPropagation();
        this.$emit('handleMailClick');
    },
  },
  data() {
    return {
      locale: data.data,
    };
  },
};
</script>

<style>
#app,
#main,
.popup-container {
  width: 100%;
  height: 100%;
}
</style>

<style scoped>
.popup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20%;
}

.popup,
.mail {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.popup {
  gap: 2rem;
  padding: 2rem;
  background-color: var(--font);
  border-radius: 12px;
  width: 100%;
}

.popup-title {
  font-size: 1.35rem;
  font-weight: 700;
  color: var(--bg);
  width: 100%;
}

.mail {
  width: 100%;
}

.mail-input,
.mail-button {
  color: var(--secondary);
  border: 2px solid var(--secondary);
  padding: 1rem;
  font-size: 1rem;
  width: 100%;
  border-radius: 12px;
}

.mail-button {
  background-color: var(--secondary);
  color: var(--font);
}

@media screen and (max-width: 564px) {
  .popup-container {
    padding: 0;
  }
}
</style>
