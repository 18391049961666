<template>
<div class="landing-payment" v-if="showPopupMail || showPopup" @click="closePopup">
  <div class="payment-popup__email" v-if="showPopupMail">
    <MailPage  @onMail="onMail" />
  </div>   
  
  <div v-else class="payment-popup" @click.stop>
    <h5 class="payment-popup__text">{{ locale.oops[lang] }}</h5>
  </div>
</div>

  <div class="landing-container">
    <section class="landing-choice">
      <header class="header-choice">
        <h2 class="choice-title">{{ locale.choose[lang] }}</h2>
        <div class="choice-time">
          <span class="choice-time__icon">
            <svg
              class="svg-inline"
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="clock"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                class=""
                fill="currentColor"
                d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"
              ></path>
            </svg>
          </span>
          <p class="choice-time__text">
            {{ locale.offer_ends[lang] }} {{ parseInt(time / 60) }}:{{
              time % 60 > 9 ? time % 60 : `0${time % 60}`
            }}
            min
          </p>
        </div>
      </header>
      <div class="choice-plans">
        <div
          v-for="(plan, key) in plans[lang]"
          @click="changeSelect(key)"
          :key="key"
          :class="key == selected ? 'choice-plan choice-active' : 'choice-plan'"
        >
          <div class="choice-part">
            <div class="ratio" @click="changeSelect(key)">
              <div class="ratio-active"></div>
            </div>
            <div class="choice-column">
              <h5 class="choice-name">{{ plan.name }}</h5>
              <p class="choice-price">
                <s v-if="plan.sale">{{ plan.sale }}</s> {{ plan.price }}
              </p>
            </div>
          </div>
          <div class="choice-column">
            <h5 class="choice-price-big">{{ plan.price_per }}</h5>
            <p class="choice-tag-big">{{ locale.per[lang] }}</p>
          </div>
          <div class="choice-decor" v-if="plan.is_popular">
            <p class="choice-tag">{{ locale.popular[lang] }}</p>
          </div>
        </div>
      </div>
      <div class="choice-great">
        <span class="choice-great__icon">
          <svg
            class="svg-inline"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="arrow-turn-down"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path
              class=""
              fill="currentColor"
              d="M32 64C14.3 64 0 49.7 0 32S14.3 0 32 0l96 0c53 0 96 43 96 96l0 306.7 73.4-73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-128 128c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 402.7 160 96c0-17.7-14.3-32-32-32L32 64z"
            ></path>
          </svg>
        </span>
        <p class="choice-great__text">
          {{ locale.achieve[lang] }}
          <span class="choice-bold">{{ locale.goal[lang] }}</span>
        </p>
      </div>
      <form action="#" class="choice-form">
        <button class="choice-button" @click.prevent="onSubmitAndLead">
          {{ locale.get_plan[lang] }}
        </button>
        <p class="choice-text">30-day money back guarantee</p>
        <span class="choice-text-small"
          >By continuing, you agree to pay 269,99 Kč for your plan and agree
          that if you don't cancel at least 24 hours prior to the end of the 1
          -week introductory offer, you will automatically be charged the full
          price of 269,99 Kč every 1 week until you cancel in settings. Learn
          more about our cancellation and refund policy in Subscription Terms.
          By continuing, I agree that my credit/debit card data will be stored
          and used for repeated purchase offers to you in the event of payment
          failure</span
        >
      </form>
    </section>
  </div>
</template>

<script>
import data from "@/locale";
import MailPage from "@/pages/MailPage.vue";
import { track } from "@amplitude/analytics-browser";
export default {
  name: "LandingChoice",
  components: {
    MailPage,
  },

  props: {
    time: Number,
    lang: String,
    plans: Object,
  },

  methods: {
    onSubmitAndLead() {
    this.sendLeadEvent();
    this.onSubmit();

    track("Get my plan");
  },

    onSubmit() {
      this.showPopupMail = true;
    },

    sendLeadEvent() {
  if (typeof fbq !== 'undefined') {
    try {
      window.fbq('track', 'Lead');
      console.log("Lead event sent to Facebook Pixel");
    } catch (error) {
      console.log("FBQ Error:", error);
    }
  } else {
    console.log("fbq is not defined");
  }
},

    onMail() {
      track("E-mail");
      this.showPopupMail = false
      this.showPopup = true;
    },

    changeSelect(key) {
      this.selected = key;
    },

    closePopup() {
      this.showPopup = false;
      this.showPopupMail = false;
    },
  },
  data() {
    return {
      selected: 0,
      showPopup: false,
      showPopupMail: false,
      locale: data.data,

    };
  },
};
</script>

<style scoped>
.landing-payment {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-popup {
  padding: 2rem;
  background-color: var(--secondary);
  border-radius: 12px;
  box-shadow: var(--box-shadow-light);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 1rem;
}

.payment-popup__email {
    padding: 0 1rem;
}

.payment-popup__text {
  font-size: 1rem;
  font-weight: 700;
}

.landing-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.landing-choice {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  gap: 1rem;
}

.header-choice {
  gap: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.choice-title {
  color: var(--font);
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
}

.choice-time {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: var(--primary);
  border-radius: 12px;
  padding: 0.45rem;
}

.svg-inline {
  display: inline-flex;
  fill: var(--font);
}

.choice-time__icon,
.choice-time__icon svg,
.choice-great__icon,
.choice-great__icon svg {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.choice-time__text {
  font-size: 1rem;
  font-weight: 500;
}

.choice-plans {
  gap: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.choice-plan {
  position: relative;
  padding: 2rem 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--secondary);
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.choice-plan:hover {
  border-color: var(--color-primary);
  transition: 0.3s ease-in-out;
}

.choice-great {
  gap: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.choice-great__text {
  font-size: 1rem;
  font-weight: 300;
}

.choice-bold {
  font-weight: 700;
}

.choice-form {
  gap: 0.85rem;
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.choice-button {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  color: var(--font);
  background-color: var(--color-primary);
  border-radius: 12px;
  animation: 2s ease 0s infinite normal none running waves;
}

.choice-text,
.choice-text-small {
  font-size: 0.65rem;
  font-weight: 300;
  text-align: center;
  width: 100%;
}

.choice-text-small {
  color: var(--secondary);
}

.choice-great__icon svg {
  fill: var(--color-primary);
  color: var(--color-primary);
  transform: rotate(180deg);
}

.choice-decor {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.45rem 1rem;
  background-color: var(--color-primary);
  border-radius: 0 0 0 12px;
}

.choice-tag {
  font-size: 0.65rem;
  font-weight: 500;
  text-transform: uppercase;
}

.choice-part {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.ratio {
  position: relative;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--secondary);
  cursor: pointer;
  border-radius: 1rem;
}

.ratio-active {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  border-radius: 100%;
  background-color: transparent;
}

.choice-active {
  border-color: var(--color-primary);
}

.choice-active .ratio-active {
  background-color: var(--color-primary);
}

.choice-column {
  display: flex;
  gap: 0.45rem;
  flex-direction: column;
}

.choice-name {
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.choice-price,
.choice-tag-big {
  font-size: 0.85rem;
  font-weight: 400;
}

.choice-price-big {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

@keyframes waves {
  0% {
    box-shadow: 0 0 0 0 var(--color-primary);
  }

  100% {
    box-shadow: transparent 0 0 0 14px;
  }
}

@media screen and (max-width: 768px) {
  .landing-choice {
    width: 100%;
  }
}
</style>
