<template>
  <ul class="test-row__select" v-if="!isOld && !isMobile()">
    <li
      v-for="(item, index) in items"
      @click="selectOption(index)"
      :key="index"
      :class="
        this.selectedIndex == index
          ? 'test-select__option test-option-active'
          : 'test-select__option'
      "
    >
      {{ item }}
    </li>
  </ul>

  <select
    class="test-select-old"
    v-if="isOld || isMobile()"
    @change="selectOption($event.target.selectedIndex - 1)"
  >
  <option value="Choose one" disabled selected>Choose one</option>
    <option
      v-for="(item, index) in items"
      :key="index"
      class="test-option-old"
      :value="item"
    >
      {{ item }}
    </option>
  </select>
</template>

<script>
export default {
  name: "MyOption",
  props: {
    isOld: Boolean,
    items: Array,
  },
  data() {
    return {
      selectedIndex: null,
    };
  },
  emits: ['onSelect'],
  methods: {
    selectOption(index) {
      this.selectedIndex = index;

      this.$emit("onSelect", index);
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.test-row__select {
  padding: 4px;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 1px solid var(--secondary);
  border-radius: 8px;
}

.test-select__option {
  cursor: pointer;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s all ease-in-out;
}

.test-select__option:hover,
.test-option-active {
  background-color: var(--active);
  transition: 0.5s all ease-in-out;
}

.test-select-old {
  padding: 1rem;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid var(--secondary);
  cursor: pointer;
  color: var(--font);
  font-size: 1rem;
  font-weight: 500;
  max-width: 65%;
}

.test-option-old {
  color: #000;
}
</style>
